import type { ComponentPropsWithRef } from 'react'

export function LoungePairPlusLogo(
  props: ComponentPropsWithRef<'svg'> & { alt?: string },
) {
  const { className, alt = 'LoungePair Plus Logo' } = props
  return (
    <svg
      className={className || ''}
      width="355"
      height="68"
      viewBox="0 0 355 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.9158C0 5.95701 5.64121 0.315796 12.6 0.315796C19.5588 0.315796 25.2 5.95701 25.2 12.9158V32.2358C25.2 34.5554 23.3196 36.4358 21 36.4358C18.6804 36.4358 16.8 34.5554 16.8 32.2358V11.2358C16.8 8.9162 14.9196 7.0358 12.6 7.0358C10.2804 7.0358 8.4 8.9162 8.4 11.2358C8.40018 18.2712 8.4 25.3066 8.4 32.342C8.4 37.8889 12.7207 42.3158 17.85 42.3158C24.5 42.3158 31.15 42.3158 37.8 42.3158C44.7588 42.3158 50.4 47.957 50.4 54.9158C50.4 61.8746 44.7588 67.5158 37.8 67.5158C31.15 67.5154 24.5 67.5158 17.85 67.5158C9.35386 67.5158 2.3574 61.2568 0.489944 53.3376C0.200461 52.11 1.80553 51.5293 2.68761 52.4308C6.63087 56.4609 12.2963 59.1072 17.8289 59.1157C25.0459 59.1245 32.263 59.1154 39.48 59.1158C41.7996 59.1158 43.68 57.2354 43.68 54.9158C43.68 52.5962 41.7996 50.7158 39.48 50.7158L17.64 50.7146C7.79093 50.5985 0 42.3268 0 32.342C0 25.8666 0 19.3912 0 12.9158Z"
        fill="#316BFF"
      />
      <path
        d="M319.124 21.8845C320.723 21.8845 321.889 22.1437 322.883 22.6189L321.328 28.4077C320.204 27.8029 318.995 27.5005 317.483 27.5005C314.718 27.5005 312.86 29.7469 312.86 33.8077V46.4221H306.553V22.2301H312.817V27.7165C313.897 23.7421 315.712 21.8845 319.124 21.8845Z"
        fill="#05203C"
      />
      <path
        d="M293.292 14.7566C293.292 12.3806 294.675 10.739 297.267 10.739C299.729 10.739 301.198 12.3806 301.198 14.7566C301.198 17.0462 299.729 18.6446 297.267 18.6446C294.675 18.6446 293.292 17.0462 293.292 14.7566ZM293.94 22.2302H300.291V46.4222H293.94V22.2302Z"
        fill="#05203C"
      />
      <path
        d="M263.868 40.763C263.868 37.9982 265.078 36.3998 266.849 35.2334C268.577 34.1102 270.91 33.4622 275.619 32.5118C279.636 31.691 280.371 30.9134 280.371 29.4878C280.371 27.9326 279.161 26.6798 276.051 26.6798C272.897 26.6798 270.996 28.4078 270.132 31.1726L264.43 29.531C266.46 24.2174 270.996 21.755 276.353 21.755C282.401 21.755 286.375 24.6926 286.375 30.8702V39.1646C286.375 41.411 287.023 42.059 288.622 42.059C289.27 42.059 289.831 41.8862 290.307 41.7134V45.7742C289.443 46.3358 287.974 46.8542 286.159 46.8542C282.271 46.8542 280.63 45.1262 280.414 41.3678C278.902 44.867 275.619 46.8542 271.515 46.8542C266.849 46.8542 263.868 44.6078 263.868 40.763ZM280.543 35.6654V34.1966C279.939 34.8878 278.643 35.363 276.094 35.9246C271.86 36.8318 270.219 37.5662 270.219 39.6398C270.219 41.411 271.515 42.2318 273.977 42.2318C278.34 42.2318 280.457 38.3006 280.543 35.6654Z"
        fill="#05203C"
      />
      <path
        d="M248.491 37.1774H241.104V46.4222H234.753V14.8862H248.016C256.612 14.8862 261.062 19.2062 261.062 25.7726C261.062 32.8574 255.964 37.1774 248.491 37.1774ZM248.059 20.7182H241.104V32.2958H247.843C252.336 32.2958 254.884 30.4814 254.884 26.507C254.884 22.3166 252.249 20.7182 248.059 20.7182Z"
        fill="#05203C"
      />
      <path
        d="M205.526 34.4558C205.526 27.6302 210.278 21.755 218.011 21.755C226.219 21.755 229.891 27.5006 229.891 33.7646V36.0974H211.617C212.136 39.5966 214.382 41.627 218.14 41.627C220.948 41.627 222.504 40.5902 223.929 38.0846L229.243 40.8926C226.996 44.5214 223.54 46.8542 218.227 46.8542C209.846 46.8542 205.526 41.627 205.526 34.4558ZM218.011 26.939C214.512 26.939 212.308 28.8398 211.704 32.2094H223.713C223.54 29.2286 221.942 26.939 218.011 26.939Z"
        fill="#05203C"
      />
      <path
        d="M176.253 51.347L182.042 48.1502C183.424 50.4398 184.936 51.6493 188.262 51.6493C192.107 51.6493 194.31 49.3166 194.31 44.2621V41.5405C192.798 44.7805 190.163 46.6382 186.232 46.6382C180.486 46.6382 176.037 42.5774 176.037 34.4557C176.037 26.0749 181.307 21.9709 186.664 21.9709C190.466 21.9709 192.928 23.4829 194.354 26.8525V22.2301H200.618V43.6573C200.618 52.7293 195.822 57.0926 187.787 57.0926C182.258 57.0926 178.413 55.0621 176.253 51.347ZM194.742 34.3261C194.742 29.9629 193.014 26.9821 188.608 26.9821C184.331 26.9821 182.474 30.0493 182.474 34.3261C182.474 38.6461 184.115 41.5405 188.608 41.5405C193.014 41.5405 194.742 38.6029 194.742 34.3261Z"
        fill="#05203C"
      />
      <path
        d="M148.013 46.4222V22.2302H154.277V27.5006C155.918 23.6126 158.813 21.755 162.528 21.755C167.841 21.755 171.254 25.5566 171.254 31.3454V46.4222H164.947V33.1166C164.947 29.2286 163.435 27.0254 159.72 27.0254C156.177 27.0254 154.32 29.747 154.32 34.1966V46.4222H148.013Z"
        fill="#05203C"
      />
      <path
        d="M141.79 22.2302V46.4222H135.526V40.979C133.884 44.9102 131.033 46.8974 127.534 46.8974C121.918 46.8974 118.98 43.0958 118.98 37.307V22.2302H125.288V35.5358C125.288 39.4238 126.627 41.627 130.256 41.627C133.668 41.627 135.483 38.5598 135.483 34.2398V22.2302H141.79Z"
        fill="#05203C"
      />
      <path
        d="M101.786 46.8542C93.7078 46.8542 89.215 41.627 89.215 34.3262C89.215 27.1118 93.9238 21.755 101.786 21.755C109.865 21.755 114.357 27.2414 114.357 34.3262C114.357 41.411 109.562 46.8542 101.786 46.8542ZM101.786 41.5406C106.193 41.5406 107.921 38.603 107.921 34.3262C107.921 29.963 106.193 26.9822 101.786 26.9822C97.5094 26.9822 95.6518 30.0494 95.6518 34.3262C95.6518 38.6462 97.2934 41.5406 101.786 41.5406Z"
        fill="#05203C"
      />
      <path
        d="M64.4 46.4222V14.8862H70.7504V40.5902H86.4752V46.4222H64.4Z"
        fill="#05203C"
      />
      <path
        d="M326.723 35.8934V29.2406H337.206V18.5558H344.329V29.2406H354.812V35.8934H344.329V46.6454H337.206V35.8934H326.723Z"
        fill="#316BFF"
      />
    </svg>
  )
}
